// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-competences-js": () => import("./../src/pages/competences.js" /* webpackChunkName: "component---src-pages-competences-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-equipe-js": () => import("./../src/pages/equipe.js" /* webpackChunkName: "component---src-pages-equipe-js" */),
  "component---src-pages-honoraires-js": () => import("./../src/pages/honoraires.js" /* webpackChunkName: "component---src-pages-honoraires-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

